import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import * as validators from '../../util/validators';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import { FaLinkedinIn, FaPinterest, FaYoutube } from 'react-icons/fa';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
  Form,
  PrimaryButton,
  FieldTextInput,
} from '../../components';
import css from './Footer.module.css';
import { toast } from 'react-toastify';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.screen.width);
  }, []);
  const fbLink = siteFacebookPage ? (
    <ExternalLink
      key="linkToFacebook"
      href="https://www.facebook.com/sommly"
      // className={css.icon}
      title={goToFb}
    >
      <IconSocialMediaFacebook /> Facebook
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href="https://twitter.com/sommlywine"
      // className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter /> Twitter
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href="https://www.instagram.com/sommly.wine/"
      // className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram /> Instagram
    </ExternalLink>
  ) : null;

  const linkedinLink = (
    <ExternalLink
      key="linkedinLink"
      href="https://www.linkedin.com/company/sommly-wine/about/"
      // className={css.icon}
      title="Go to linkedin"
    >
      <FaLinkedinIn style={{ color: '#fff' }} /> Linkedin
    </ExternalLink>
  );
  const pinterestLink = (
    <ExternalLink
      key="pinterestLink"
      href="https://www.pinterest.com/Sommly_wine/"
      // className={css.icon}
      title="Go to linkedin"
    >
      <FaPinterest style={{ color: '#fff' }} /> Pinterest
    </ExternalLink>
  );
  const youtubeLink = (
    <ExternalLink
      key="youtubeLink"
      href="https://www.youtube.com/channel/UCWtlj-JLCXa3sDexGWJ0UIw/featured"
      // className={css.icon}
      title="Go to linkedin"
    >
      <FaYoutube style={{ color: '#fff' }} /> Youtube
    </ExternalLink>
  );
  const twoExtraForMobile = width <= 1280 && (
    <>
      <li className={css.listItem}>
        <ExternalLink href="https://blog.sommly.com/docs/">Documentation</ExternalLink>
      </li>{' '}
      <li className={css.listItem}>
        <ExternalLink href="https://blog.sommly.com/affiliate-program/">
          Referral Program
        </ExternalLink>
      </li>
    </>
  );
  return [twoExtraForMobile, fbLink, twitterLink, instragramLink, linkedinLink, youtubeLink].filter(
    v => v != null
  );
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  const [email, setEmail] = useState('');
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.screen.width);
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    if (email === '') {
      toast.error('Email is required');
    } else {
      fetch('https://hooks.zapier.com/hooks/catch/11291651/bsrun9b/', {
        method: 'POST',
        body: JSON.stringify({
          emailto: 'info@sommly.com',
          emailFrom: email,
          subject: 'Sommly News Subscription',
          body: `
            <div style="width: 100%; min-height:500px; text-align:center;">
              <div style="margin: 50px 0 30px;">
                <img width="100" height="100" src="https://sommly-image-bucket.s3.us-east-2.amazonaws.com/sommly.png" alt="">
              </div>
              <div>
                <h4>Hello!</h4>
                <h5>You have received a News Subscription email from '${email}'.</h5>
              </div>
              <div style="margin: 20px 0 50px;">
                <p>Contect us: <span style="color: blue; text-decoration: underline">support@sommly.com</span></p>
              </div>
            </div>
          `,
        }),
      })
        .then(res => res.json())
        .then(data => {
          if (data) {
            setEmail('');
            toast.success('Your request for sommly news subscription sent successfully');
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.linksWrapper}>
            <div className={css.organization} id="organization">
              {/* <NamedLink name="LandingPage" className={css.logoLink}> */}
              <Logo format="desktop" className={css.logo} />
              {/* </NamedLink> */}

              <ul className={css.list}>
                <li className={css.listItem}>
                  <h3>Get Wine News in Your Inbox</h3>
                  <input
                    className={css.emailInput}
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder="Your Email Address"
                  />
                  <button type="submit" onClick={handleSubmit} className={css.subscriibeBtn}>
                    Subscribe
                  </button>
                </li>
              </ul>
            </div>

            <div className={css.links}>
              <div className={css.appLinks}>
                <div>
                  <ul className={css.list}>
                    <li className={css.listItem}>
                      <NamedLink name="ResearchWineries">Research Wineries</NamedLink>
                    </li>
                    <li className={css.listItem}>
                      <NamedLink name="BuyWine">Buy Wine</NamedLink>
                    </li>
                    <li className={css.listItem}>
                      <NamedLink name="AboutPage">About</NamedLink>
                    </li>
                    <li className={css.listItem}>
                      <ExternalLink key="blog" href="https://blog.sommly.com/">
                        Blog
                      </ExternalLink>
                    </li>
                    <li className={css.listItem}>
                      <NamedLink name="WhysommlyPage">Why Sommly</NamedLink>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className={css.list}>
                    <li className={css.listItem}>
                      <NamedLink name="WineryPlansPage">Winery Plans</NamedLink>
                    </li>
                    <li className={css.listItem}>
                      <NamedLink name="PrivacyPolicyPage">Privacy Policy</NamedLink>
                    </li>
                    <li className={css.listItem}>
                      <NamedLink name="TermsOfServicePage">Terms of Use</NamedLink>
                    </li>{' '}
                    {!(width <= 1280) && (
                      <>
                        <li className={css.listItem}>
                          <ExternalLink href="https://blog.sommly.com/docs/">
                            Documentation
                          </ExternalLink>
                        </li>{' '}
                        <li className={css.listItem}>
                          <ExternalLink href="https://blog.sommly.com/affiliate-program/">
                            Referral Program
                          </ExternalLink>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
              <div className={css.socialLinks}>{socialMediaLinks}</div>
            </div>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
              {/* <FormattedMessage id="Footer.copyright" /> */}
              Copyright © 2023 Sommly LLC
            </NamedLink>
            <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </NamedLink>
              <NamedLink name="TermsOfServicePage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </NamedLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
